.header {
  background-color: #333;
  padding: 1vh 0;
  display: flex;
  height: 6vh;
  justify-content: center;
  align-items: center;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 4vh;
}

.header nav ul li a {
  font: 3vh Arial;
  color: white;
  text-decoration: none;
  font-size: 3vh;
}
