.project-page {
    font-size: 20px;
}

.project-page .content {
    font-size: 18px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 50%;
    max-width: 50rem;
}

.project-page h2 {
    font-size: 24px;
}

.project-page img {
    width: auto;
    height: 16rem;
}

.project-page a {
    font-weight: bold;
}

@media (max-width: 768px) {
    .project-page {
        font-size: 16px;
    }

    .project-page .content {
        width: 80%;
        font-size: 16px;
        align-items: center;
        text-align: left;
    }

    .project-page h2 {
        font-size: 20px;
    }

    .project-page img {
        width: 80%;
        height: auto;
    }

    .project-page a {
        font-size: 1rem;
    }
}
