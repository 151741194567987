.projects {
    font-size: 20px;
}

.projects h2 {
    font-size: 24px;
}

.projects img {
    width: auto;
    height: 16rem;
}

@media (max-width: 768px) {
    .projects {
        font-size: 16px;
    }

    .projects h2 {
        font-size: 20px;
    }

    .projects img {
        width: 80%;
        height: auto;
    }

    .projects div {
        margin-bottom: 1rem;
    }
}
