.home {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 1.25rem;
}

.home .content {
  width: 100%;
  max-width: 40rem;
}

.home .top-section {
  display: flex;
  align-items: center;
  gap: 1.875rem;
  margin-bottom: 1.25rem;
}

.home img {
  max-width: 18.625rem;
  border-radius: 0.625rem;
}

.home .info {
  max-width: 40rem;
  color: #222;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .home .top-section {
    flex-direction: column;
    gap: 1rem;
  }

  .home img {
    max-width: 10rem;
  }

  .home .info {
    font-size: 1rem;
  }
}
