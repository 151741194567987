html {
  font-size: 16px;
}

body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  color: #333;
  line-height: 1.4;
  font-size: 1rem;
}

a {
  color: #222;
  text-decoration: none;
}

a[link-type="hidden"] {
  color: #222;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}