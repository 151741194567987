.courses table {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.courses th, .courses td {
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
  text-align: left;
}

.courses th {
  background-color: #f5f5f5;
}

.courses tr.row-gray-light {
  background-color: #fafafa;
}

.courses tr.row-gray-dark {
  background-color: #f0f0f0;
}

.courses .grade-name, .ects {
  text-align: left;
}

.courses .grade-value {
  text-align: center;
}

.courses .note {
  font-size: 0.9em;
  color: #555;
  margin: 0 15%;
  text-indent: 2em;
}

@media (max-width: 768px) {
  .courses table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .courses th, .courses td {
    padding: 8px 12px;
    font-size: 14px;
  }

  .courses .note {
    margin: 0 5%;
    font-size: 0.8em;
  }
}
